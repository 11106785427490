import React from "react"
import { outlookAddinPage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const OutlookAddin = () => {
  return (
    <Layout>
      <Seo
        title="Efficient Client Data Management with Synkli Add-In for Outlook"
        description={`Enhance client data management with Synkli Add-In for Outlook, designed for accountants. Streamline workflows and organise client info efficiently.`}
      />

      <FeatureDetailsPage data={outlookAddinPage} />
      
    </Layout>
  )
}

export default OutlookAddin
